import { SVGIcon } from "./components/SVGIcon"
import { motion } from "framer-motion"
import { FaWhatsapp } from "react-icons/fa"
import { AiOutlineMail } from "react-icons/ai"

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: { delay: 3.5, duration: .5 }
  }
};

const item2 = {
  hidden: { y: 0, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: { delay: 3.3, duration: .5 }
  }
};

const d1 = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: { delay: 4, duration: .5 }
  }
};

const d2 = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: { delay: 4.5, duration: .5 }
  }
};

const d3 = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: { delay: 5, duration: .5 }
  }
};

const icon1 = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: { delay: 5.5, duration: .5 }
  }
};

const icon2 = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: { delay: 6, duration: .5 }
  }
};
export const App = () => {
  return (
    <div>
      <section>
        
        <div className="flex-col items-start justify-start px-12">
          <SVGIcon />
        <div className="flex flex-col gap-12 justify-between sm:-mt-32 sm:mb-12">
        <motion.p
            initial="hidden"
            animate="visible"
                    className="text-center text-sm max-w-sm sm:max-w-4xl sm:text-2xl sm:text-2lg mx-auto font-bold px-12 py-6 border border-dashed"
            variants={item2}
          >
                        Size Daha iyi bir hizmet verebilmek için sitemizde bakım çalışması yapıyoruz. Yaşanan aksaklıklardan ötürü özür dileriz.
          </motion.p>    
          <motion.p
            initial="hidden"
            animate="visible"
            className="text-center text-md max-w-4xl text-sm sm:text-2lg mx-auto font-bold px-12"
            variants={item}
          >
            MF Plastik olarak dayanıklı ve yüksek kaliteli PVC dolapları üretiyoruz.
            Ürünlerimiz en kaliteli malzemelerden üretilir ve müşterilerimizin ihtiyaçlarına göre özelleştirilebilir. Dayanıklı yapısı sayesinde, uzun yıllar sorun cikarmadan kullanilabilir.
            MF Plastik olarak kaliteli ürünlerimizle müşterilerimizin memnuniyetini en ön planda tutuyoruz.
          </motion.p>

                    </div>
          <div className="flex items-center justify-center mt-10 -mb-16 gap-4 sm:gap-6">
            <motion.p
              initial="hidden"
              animate="visible"
              className="text-lg sm:text-xl font-bold text "
              variants={d1}
            >
              Banyo Mobilyalari
            </motion.p>
            <motion.p
              initial="hidden"
              animate="visible"
              className="text-lg sm:text-xl font-bold text "
              variants={d2}
            >
              Ayakkabılık
            </motion.p>
            <motion.p
              initial="hidden"
              animate="visible"
              className="text-lg sm:text-xl font-bold text "
              variants={d3}
            >
              Kombi Dolabı
            </motion.p>
          </div>
          <div className="flex items-center justify-center gap-8 mt-28 -mb-28">
            <motion.div initial="hidden" variants={icon1} animate="visible" className="">
              <a href="https://api.whatsapp.com/send?phone=+905511895166">
              <FaWhatsapp size={45} />
              </a>
            </motion.div>
            <motion.div initial="hidden" variants={icon2} animate="visible" className=" ">
              <a href="mailto:info@mfplastik.com">
              <AiOutlineMail size={45} />
              </a>
            </motion.div>
          </div>

        </div>
      </section>
      <section>
      </section>
    </div>

  )
}
